import {OdemeTipiEnum} from "./OdemeTipleri";
import {IbanTercihiEnum} from "../uyap/IbanTercihiEnum";
import {VakifbankHesapBilgileri} from "../uyap/VakifbankHesapListesi";

export class DosyaFormDataInterface {
    kotaKullanimSekli: string = "0";
    ibanTercihi: IbanTercihiEnum = IbanTercihiEnum.Avukat;
    avukatIban: string = "";
    il: string = "";
    adliye: string = "";
    alacakliEposta: string = "";
    alacakliTelefon: string = "";
    bankaDosyasi: boolean = false;
    gayrimenkulDosyasi: boolean = false;
    alacakliMernisAdresiKullan: boolean = false;
    mernisAdresiKullan: boolean = false;
    borcluOlumKaydiOlanlarTakiptenCikarilsin: boolean = true;
    borcluOlumKaydiOlanlarinTcSilinsin: boolean = false;
    alacakKalemIslemisFaizlerToplansin: boolean = false;//false
    odemeTipi: OdemeTipiEnum = OdemeTipiEnum.Barokart;
    vakifbankHesapBilgileri: VakifbankHesapBilgileri = {
        bakiyeDurumu: "",
        hesapKartNo: "",
        hesapTipi: "",
        subeAdi: "",
        subeKodu: "",
        masrafTutari: "",
        odemeYontemi: ""
    };
    tebligatGideri: {
        enabled: boolean,
        tutar: any,
        aciklama: string
    } = {
        enabled: false,
        tutar: "",
        aciklama: ""
    }
}