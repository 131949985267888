

import {Component, Vue} from "vue-property-decorator";
import {TaskTuru} from "@/plugins/uyap-plugin/enum/TaskTuru";
import TakipAcFormWizard from "@/components/forms/form-wizard/eicrapro/TakipAcFormWizard.vue";
import UyapBagliDegilView from "@/views/eicrapro/UyapBagliDegilView.vue";

@Component({
  components: {UyapBagliDegilView, TakipAcFormWizard}
})
export default class TakipAcView extends Vue {
  taskTuru = TaskTuru;
}
